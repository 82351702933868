import { TOKEN } from 'constants/token'
import { FORM_ORIGIN, LOCATION } from 'constants/location'

export const authRedirectInterceptor = error => {
  if (error?.response?.status === 401) {
    const { pathname, search, hash } = window.location
    const isFormOrigin = sessionStorage.getItem(FORM_ORIGIN)
    !isFormOrigin && localStorage.setItem(LOCATION, pathname + search + hash)
    !isFormOrigin && (window.location.href = `${process.env.PUBLIC_URL}/login`)
  }
  return Promise.reject(error)
}

export const userTokenInterceptor = config => {
  const token = localStorage.getItem(TOKEN)
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}
